import React, { useEffect, useState } from "react";
import Image from "next/image";
import logo from "../../public/images/highreslogo.png";
import menuSloth from "../../public/images/menusloth.png";
import Link from "next/link";
import Cart from "./Cart";
import TrustpilotWidget from "./TrustpilotWidget";
import { usePathname } from "next/navigation";
import SearchBox from "./SearchBox";
import { useRouter } from "next/router";
import { motion, AnimatePresence } from "framer-motion";

const NavLinks = [
  {
    name: "Home",

    link: "/",
  },
  {
    name: "Products",

    link: "/products",
  },
  {
    name: "Wholesale",

    link: "/wholesale",
  },
  {
    name: "About",

    link: "/about",
  },
  {
    name: "Contact us",

    link: "/contact-us",
  },
];

const Navbar = ({
  cart,
  addToCart,
  removeFromCart,
  subTotal,
  setToggleCart,
  toggleCart,
  handleToggleCart,
}) => {
  const pathname = usePathname();

  const [mobileMenu, setMobileMenu] = useState(false);
  const [searchBox, setSearchBox] = useState(false);

  const router = useRouter();

  // Handle page change event
  useEffect(() => {
    const handleRouteChange = () => {
      // Reset the state when changing pages
      setSearchBox(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up the event listener
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  const handleToggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    setSearchBox(false);
    setToggleCart(false);
  };

  const handleToggleSearchBox = () => {
    setSearchBox(!searchBox);
    setMobileMenu(false);
    setToggleCart(false);
  };

  return (
    <>
      <div className="relative h-[17vh] lg:h-[15vh] w-screen bg-[#F4F8FF]">
        {/* cart popup */}
        <AnimatePresence>
          {toggleCart && (
            <Cart
              closeBtn={handleToggleCart}
              cart={cart}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              subTotal={subTotal}
            />
          )}
        </AnimatePresence>

        {/* cart popup */}

        <AnimatePresence>
          {mobileMenu && (
            <div
              onClick={handleToggleMobileMenu}
              className=" z-40 lg:hidden flex items-center justify-end w-screen h-screen top-0 fixed bg-black bg-opacity-70"
            >
              <motion.div
                key="mobileMenu"
                initial={{ x: "80%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "80%", opacity: 0, transition: { duration: 0.3 } }}
                transition={{ duration: 0.3, ease: "easeIn" }}
                className="z-50 text-[#1E1E1E] text-3xl space-y-4 px-4 flex flex-col items-start justify-start bg-[#F4F8FF] w-[60%] h-[83vh] lg:h-[85vh]  fixed top-[17%]"
              >
                <div className="flex items-center justify-center w-full">
                  <div className="w-[60%] ">
                    <Image src={menuSloth} alt="cute sloth hanging" />
                  </div>
                </div>
                {NavLinks.map((link, key) => (
                  <Link key={key} className="w-full" href={link.link}>
                    <p
                      className={
                        pathname == link.link
                          ? "text-white bg-[#294870] text-lg px-4 rounded-lg py-1 font-bold "
                          : "bg-gray-200 px-4 rounded-lg py-1 text-lg font-semibold text-[#1E1E1E] "
                      }
                    >
                      {link.name}
                    </p>
                  </Link>
                ))}
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>

      {/* desktop Navbar */}
      <nav className=" flex flex-col-reverse lg:flex-col top-0  w-screen fixed z-50 h-[17vh] lg:h-[15vh]  bg-[#F4F8FF]">
        <div className="flex items-center justify-center w-full">
          <TrustpilotWidget />
        </div>

        <div className="flex py-3 lg:py-1 items-center lg:px-0 px-4 justify-between lg:justify-around">
          <Link href="/" className="relative w-[40%] lg:w-[10%]">
            <Image src={logo} alt="Logo" />
          </Link>
          {/* links */}
          <div className="hidden lg:flex items-center space-x-6">
            {NavLinks.map((link, key) => (
              <Link key={key} href={link.link}>
                <p
                  className={
                    pathname == link.link
                      ? "text-[#294870] font-bold underline decoration-[#294870] decoration-4 underline-offset-4"
                      : "hover:scale-110 transition text-[#1E1E1E] cursor-pointer"
                  }
                >
                  {link.name}
                </p>
              </Link>
            ))}
          </div>

          {/* icons */}
          <div className=" flex items-center space-x-3 lg:space-x-5">
            <span
              onClick={handleToggleSearchBox}
              className="   cursor-pointer "
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                className="hover:scale-110 transition"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9697 16.9697C17.2626 16.6768 17.7374 16.6768 18.0303 16.9697L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L16.9697 18.0303C16.6768 17.7374 16.6768 17.2626 16.9697 16.9697Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M17.5 16.25C17.8339 16.25 18.1478 16.38 18.3839 16.6161L22.8839 21.1161C23.12 21.3522 23.25 21.6661 23.25 22C23.25 22.3339 23.12 22.6478 22.8839 22.8839C22.6478 23.12 22.3339 23.25 22 23.25C21.6661 23.25 21.3522 23.12 21.1161 22.8839L16.6161 18.3839C16.38 18.1478 16.25 17.8339 16.25 17.5C16.25 17.1661 16.38 16.8522 16.6161 16.6161C16.8522 16.38 17.1661 16.25 17.5 16.25ZM22 22.25C22.0387 22.25 22.1131 22.2405 22.1768 22.1768C22.2405 22.1131 22.25 22.0387 22.25 22C22.25 21.9613 22.2405 21.8869 22.1768 21.8232L17.6768 17.3232C17.6131 17.2595 17.5387 17.25 17.5 17.25C17.4613 17.25 17.3869 17.2595 17.3232 17.3232C17.2595 17.3869 17.25 17.4613 17.25 17.5C17.25 17.5387 17.2595 17.6131 17.3232 17.6768L21.8232 22.1768C21.8869 22.2405 21.9613 22.25 22 22.25Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M10.5 0C16.2897 0 21 4.71028 21 10.5C21 16.2897 16.2897 21 10.5 21C4.71028 21 0 16.2897 0 10.5C0 4.71028 4.71028 0 10.5 0ZM10.5 19C15.1869 19 19 15.1869 19 10.5C19 5.81308 15.1869 2 10.5 2C5.81308 2 2 5.81308 2 10.5C2 15.1869 5.81308 19 10.5 19Z"
                  fill="#1E1E1E"
                />
              </svg>
            </span>

            <span
              onClick={handleToggleCart}
              className="relative hover:scale-110 transition cursor-pointer "
            >
              <svg
                width={17}
                height={21}
                viewBox="0 0 17 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.49973 0C6.42866 0 4.74973 1.67893 4.74973 3.75V4.75977C2.85742 4.89174 1.30025 6.34586 1.06175 8.25386L0.0617513 16.2539C-0.236676 18.6413 1.62487 20.75 4.03086 20.75H12.9686C15.3746 20.75 17.2362 18.6413 16.9377 16.2539L15.9377 8.25386C15.6992 6.34586 14.1421 4.89174 12.2498 4.75977V3.75C12.2498 1.67893 10.5708 0 8.49973 0ZM10.7498 4.75V3.75C10.7498 2.50736 9.74237 1.5 8.49973 1.5C7.25709 1.5 6.24973 2.50736 6.24973 3.75V4.75H10.7498Z"
                  fill="#294870"
                />
              </svg>
              {Object.keys(cart).length > 0 ? (
                <div className="text-center flex items-center justify-center text-white font-semibold text-[11px] bg-[#294870] -top-2 -right-3 absolute w-[1.15rem] h-[1.15rem] rounded-full">
                  {Object.keys(cart).length}
                </div>
              ) : null}
            </span>

            {mobileMenu ? (
              <svg
                onClick={handleToggleMobileMenu}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="flex lg:hidden w-8 h-8 text-[#294870]"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <span onClick={handleToggleMobileMenu} className="flex lg:hidden">
                <svg
                  width={25}
                  height={20}
                  viewBox="0 0 19 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.99512 1H18.0002"
                    stroke="#294870"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M1.99512 6.55957H18.0002"
                    stroke="#294870"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M1.99512 12.1191H18.0002"
                    stroke="#294870"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            )}
            <AnimatePresence>
              {searchBox && <SearchBox onc={handleToggleSearchBox} />}
            </AnimatePresence>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
